import swiper from "swiper";



//--------------------------------------------------------
//top-heroスライド
//--------------------------------------------------------
var slideCount = $('.top-hero').find(".swiper-slide").length;
if (slideCount > 1) {
  //console.log(slideCount);
  var serviseNav = new swiper(".top-hero-slider", {
    direction: "horizontal",
    effect: "fade",
    loop: true,
    speed: 1500,
    autoplay: true,
    spaceBetween: 0,
    centeredSlides: true,
    observer: true,
    allowTouchMove: true,
    preloadImages: false,
    autoHeight: true,
    slidesPerView: 1,
    // lazy: {
    //   loadPrevNext: true,
    //   loadPrevNextAmount: 2,
    // },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    //breakpoints: {
      // 960: {
      //   slidesPerView: 2
      // },
      // 414: {
      //   autoHeight: false,
      //   slidesPerView: 1
      // }
    //},
    // navigation: {
    //   nextEl: ".top-hero-slider-next",
    //   prevEl: ".top-hero-slider-prev",
    // }
  });
} else {
  //$('.top-hero').find(".top-hero-slider-next").hide();
  //$('.top-hero').find(".top-hero-slider-prev").hide();
}

