import swiper from "swiper";

//import swiper from "slick-carousel";


//--------------------------------------------------------
//スライド
//--------------------------------------------------------
var caseSlideCount = $('.property-contents-single__slide__wrap').find(".swiper-slide").length;
if (caseSlideCount > 1) {

    // $('.slider-for').slick({
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   arrows: false,
    //   fade: true,
    //   asNavFor: '.slider-nav'
    // });  
    // $('.slider-nav').slick({
    //   slidesToShow: 5,
    //   slidesToScroll: 1,
    //   asNavFor: '.slider-for',
    //   dots: true,
    //   centerMode: true,
    //   focusOnSelect: true,
    // }); 

  let thumbs = new swiper('.property-contents-single__slide__thumb', {
    centeredSlides: true,
    spaceBetween: 5,
    loop: true,
    slidesPerView: "auto",
    touchRatio: 0.2,
    slideToClickedSlide: true,
    breakpoints: {
      768: {
        spaceBetween: 5,
      }
    },
  });
  var caseSlideNav = new swiper(".property-contents-single__slide__wrap", {
    slidesPerView: 1,
    speed: 1500,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    slidesPerView: "auto",
    loop: true,
    onSlideChangeEnd : (s) => {s.fixLoop();},
    centeredSlides : true,
    disableOnInteraction: true,
    slideToClickedSlide: true,
    navigation: {
      nextEl: ".property-contents-single__slide-next",
      prevEl: ".property-contents-single__slide-prev",
    }
  });

  caseSlideNav.controller.control = thumbs;
  thumbs.controller.control = caseSlideNav;
} else {
  $('.property-contents-single').find(".property-contents-single__slide-next").hide();
  $('.property-contents-single').find(".property-contents-single__slide-prev").hide();
  $('.property-contents-single').find(".property-contents-single__slide__thumb").hide();
}


