
$(function(){

  /*------------------------------
  CATEGORIES の件数
  --------------------------------*/
  var listContents = $("#entry_list_more li").length;
  if(listContents <= 10) {
    $('#more_btn').hide();
    $('#close_btn').hide();
  } else {
    //console.log(listContents);
    $("#entry_list_more").each(function(){
  
      //最初に表示させるアイテムの数
      var Num = 10;
  
      //最初はmoreボタン表示にし、
      $(this).find('#more_btn').show();
      $(this).find('#close_btn').hide();
      //10行目まで表示
      $(this).find("li:not(:lt("+Num+"))").hide();
  
      //moreボタンがクリックされた時
      $('#more_btn').click(function(){
      //Numに+3ずつしていく = 3行ずつ追加する
        Num +=5;
        $(this).parent().find("li:lt("+Num+")").slideDown();
        //console.log($(this).parent());
        //liの個数よりNumが多い時、
        if(listContents <= Num){
          Num = 10;//「閉じる」がクリックされた後、表示させるアイテムの数
          gtNum = Num-1;
          $('#more_btn').hide();
          $('#close_btn').show();
  
          //「閉じる」がクリックされたら、
          $('#close_btn').click(function(){
            $(this).parent().find("li:gt("+gtNum+")").slideUp();//11行目以降は非表示にし、
            $(this).hide();//閉じるボタンを非表示
            $('#more_btn').show();//moreボタン表示に
          });
        }
      });
    });
  }
});



$(function(){
  /*------------------------------
  RECENT ENTRIES の件数
  --------------------------------*/
  var listContents2 = $("#entry_list_more2 li").length;
  if(listContents2 <= 10) {
    $('#more_btn2').hide();
    $('#close_btn2').hide();
  } else {
    $("#entry_list_more2").each(function(){
  
      //最初に表示させるアイテムの数
      var Num2 = 10;
  
      //最初はmoreボタン表示にし、
      $(this).find('#more_btn2').show();
      $(this).find('#close_btn2').hide();
      //10行目まで表示
      $(this).find("li:not(:lt("+Num2+"))").hide();
  
      //moreボタンがクリックされた時
      $('#more_btn2').click(function(){
      //Numに+3ずつしていく = 3行ずつ追加する
      Num2 +=5;
        $(this).parent().find("li:lt("+Num2+")").slideDown();
        //console.log($(this).parent());
        //liの個数よりNumが多い時、
        if(listContents2 <= Num2){
          Num2 = 10;//「閉じる」がクリックされた後、表示させるアイテムの数
          gtNum2 = Num2-1;
          $('#more_btn2').hide();
          $('#close_btn2').show();
  
          //「閉じる」がクリックされたら、
          $('#close_btn2').click(function(){
            $(this).parent().find("li:gt("+gtNum2+")").slideUp();//11行目以降は非表示にし、
            $(this).hide();//閉じるボタンを非表示
            $('#more_btn2').show();//moreボタン表示に
          });
        }
      });
    });
  }
});





$(function(){
  /*------------------------------
  RECENT ENTRIES の件数
  --------------------------------*/
  var listContents3 = $("#entry_list_more3 li").length;
  if(listContents3 <= 12) {
    $('#more_btn3').hide();
    $('#close_btn3').hide();
  } else {
    $("#entry_list_more3").each(function(){
  
      //最初に表示させるアイテムの数
      var Num3 = 12;
  
      //最初はmoreボタン表示にし、
      $(this).find('#more_btn3').show();
      $(this).find('#close_btn3').hide();
      //10行目まで表示
      $(this).find("li:not(:lt("+Num3+"))").hide();
  
      //moreボタンがクリックされた時
      $('#more_btn3').click(function(){
      //Numに+3ずつしていく = 3行ずつ追加する
      Num3 +=5;
        $(this).parent().find("li:lt("+Num3+")").slideDown();
        //console.log($(this).parent());
        //liの個数よりNumが多い時、
        if(listContents3 <= Num3){
          Num3 = 12;//「閉じる」がクリックされた後、表示させるアイテムの数
          gtNum3 = Num3-1;
          $('#more_btn3').hide();
          $('#close_btn3').show();
  
          //「閉じる」がクリックされたら、
          $('#close_btn3').click(function(){
            $(this).parent().find("li:gt("+gtNum3+")").slideUp();//11行目以降は非表示にし、
            $(this).hide();//閉じるボタンを非表示
            $('#more_btn3').show();//moreボタン表示に
          });
        }
      });
    });
  }
});