
$(function(){
  $('.hamburger-menu').on('click', function() {
    $(this).toggleClass('hamburger-menu-active');
    if($(this).hasClass('hamburger-menu-active')){
      $('.l-header__side').addClass('-open-menu');
      $('.container').addClass('-open-menu');
      $('.l-header__side-red').addClass('-open-menu');
      $('.l-header__side-red-list').addClass('-open-menu');
    } else {
      $('.l-header__side').removeClass('-open-menu');
      $('.container').removeClass('-open-menu');
      $('.l-header__side-red').removeClass('-open-menu');
      $('.l-header__side-red-list').removeClass('-open-menu');
    }
  })
});