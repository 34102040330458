import autoHeightItem from '../../plugins/autoHeightItem.js';

/*
    autoHeightItem
    高さ揃え（レスポンシブ対応）のプラグインです

    autoHeightParentsClass   高さを揃えたい要素が全て内包されている親のユニークなclass名
    autoHeightClass   高さを揃えたい要素のユニークなclass名
    column   デフォルトで揃えたい数
    breakpoints   設定したポイントで揃えたいカラム数を再指定

    ※カラム数を1以下に設定した場合は高さ揃えは行われません。
    ※１を設定しない限り高さ揃えは行われるので注意してください。
*/


let options = [
  {
    autoHeightParentsClass: "list01",
    autoHeightClass: "js-list01",
    column: 3,
    breakpoints: {
      // breakPoint1
      768: {
        column: 2
      },
      // breakPoint2
      450: {
        column: 1
      }
    }
  },{
    autoHeightParentsClass: "list02",
    autoHeightClass: "js-list02",
    column: 3,
    breakpoints: {
      // breakPoint1
      768: {
        column: 2
      },
      // breakPoint2
      450: {
        column: 1
      }
    }
  },{
    autoHeightParentsClass: "list03",
    autoHeightClass: "js-list03",
    column: 3,
    breakpoints: {
      // breakPoint1
      768: {
        column: 2
      },
      // breakPoint2
      450: {
        column: 1
      }
    }
  }

];

window.addEventListener('load', function() {
  autoHeightItem.init(
    options
  );


  var myWindow = window.innerHeight;
  //console.log(myWindow);

  /*------------------------------
  2L の件数
  --------------------------------*/
  var prolistContents01 = $("#2lItem .property-contents-main__list-item").length;
  if(prolistContents01 <= 6) {
    $('#contents01 .property-contents-main__more').hide();
    $('#contents01 .property-contents-main__close').hide();
  } else {
    $("#contents01 #2lItem .property-contents-main__list-item").each(function(){
  
      //最初に表示させるアイテムの数
      var Num = 6;
  
      //最初はmoreボタン表示にし、
      $('#contents01 .property-contents-main__more').show();
      $('#contents01 .property-contents-main__close').hide();
      //10行目まで表示
      $("#2lItem > li:not(:lt("+Num+"))").hide();
  
      //moreボタンがクリックされた時
      $('#contents01 .property-contents-main__more').click(function(){
        event.preventDefault();
        //Numに+3ずつしていく = 3行ずつ追加する
        Num +=6;
        $("#2lItem > li:lt("+prolistContents01+")").slideDown();
        let optionsPro01 = [
          {
            autoHeightParentsClass: "list01",
            autoHeightClass: "js-list01",
            column: 3,
            breakpoints: {
              // breakPoint1
              768: {
                column: 2
              },
              // breakPoint2
              450: {
                column: 1
              }
            }
          }
        ];
        if(myWindow > 500) {
          autoHeightItem.init(optionsPro01);
        }
        
        $('#contents01 .property-contents-main__more').hide();
        $('#contents01 .property-contents-main__close').show();

        Num = 6;//「閉じる」がクリックされた後、表示させるアイテムの数
        var pro01gtNum = Num-1;
        $('#contents01 .property-contents-main__close').click(function(){
          event.preventDefault();
          $("#2lItem > li:gt("+pro01gtNum+")").slideUp();//11行目以降は非表示にし、
          
          $(this).hide();//閉じるボタンを非表示
          $('#contents01 .property-contents-main__more').show();//moreボタン表示に
        });

      });
    });
  }

  /*------------------------------
  3L の件数
  --------------------------------*/
  var prolistContents02 = $("#3lItem .property-contents-main__list-item").length;
  if(prolistContents02 <= 6) {
    $('#contents02 .property-contents-main__more').hide();
    $('#contents02 .property-contents-main__close').hide();
  } else {
    $("#contents02 #3lItem .property-contents-main__list-item").each(function(){
  
      //最初に表示させるアイテムの数
      var Num = 6;
  
      //最初はmoreボタン表示にし、
      $('#contents02 .property-contents-main__more').show();
      $('#contents02 .property-contents-main__close').hide();
      //10行目まで表示
      $("#3lItem > li:not(:lt("+Num+"))").hide();
  
      //moreボタンがクリックされた時
      $('#contents02 .property-contents-main__more').click(function(){
        event.preventDefault();
        //Numに+3ずつしていく = 3行ずつ追加する
        Num +=6;
        $("#3lItem > li:lt("+prolistContents02+")").slideDown();
        let optionsPro02 = [
          {
            autoHeightParentsClass: "list02",
            autoHeightClass: "js-list02",
            column: 3,
            breakpoints: {
              // breakPoint1
              768: {
                column: 2
              },
              // breakPoint2
              450: {
                column: 1
              }
            }
          }
        ];
        if(myWindow > 500) {
          autoHeightItem.init(optionsPro02);
        }
        
        $('#contents02 .property-contents-main__more').hide();
        $('#contents02 .property-contents-main__close').show();

        Num = 6;//「閉じる」がクリックされた後、表示させるアイテムの数
        var pro02gtNum = Num-1;
        $('#contents02 .property-contents-main__close').click(function(){
          event.preventDefault();
          $("#3lItem > li:gt("+pro02gtNum+")").slideUp();//11行目以降は非表示にし、
          
          $(this).hide();//閉じるボタンを非表示
          $('#contents02 .property-contents-main__more').show();//moreボタン表示に
        });

      });
    });
  }


  /*------------------------------
  4L の件数
  --------------------------------*/
  var prolistContents03 = $("#4lItem .property-contents-main__list-item").length;
  if(prolistContents03 <= 6) {
    $('#contents03 .property-contents-main__more').hide();
    $('#contents03 .property-contents-main__close').hide();
  } else {
    $("#contents03 #4lItem .property-contents-main__list-item").each(function(){
  
      //最初に表示させるアイテムの数
      var Num = 6;
  
      //最初はmoreボタン表示にし、
      $('#contents03 .property-contents-main__more').show();
      $('#contents03 .property-contents-main__close').hide();
      //10行目まで表示
      $("#4lItem > li:not(:lt("+Num+"))").hide();
  
      //moreボタンがクリックされた時
      $('#contents03 .property-contents-main__more').click(function(){
        event.preventDefault();
        //Numに+3ずつしていく = 3行ずつ追加する
        Num +=6;
        $("#4lItem > li:lt("+prolistContents03+")").slideDown();
        let optionsPro03 = [
          {
            autoHeightParentsClass: "list03",
            autoHeightClass: "js-list03",
            column: 3,
            breakpoints: {
              // breakPoint1
              768: {
                column: 2
              },
              // breakPoint2
              450: {
                column: 1
              }
            }
          }
        ];
        if(myWindow > 500) {
          autoHeightItem.init(optionsPro03);
        }
        
        $('#contents03 .property-contents-main__more').hide();
        $('#contents03 .property-contents-main__close').show();

        Num = 6;//「閉じる」がクリックされた後、表示させるアイテムの数
        var pro03gtNum = Num-1;
        $('#contents03 .property-contents-main__close').click(function(){
          event.preventDefault();
          $("#4lItem > li:gt("+pro03gtNum+")").slideUp();//11行目以降は非表示にし、
          
          $(this).hide();//閉じるボタンを非表示
          $('#contents03 .property-contents-main__more').show();//moreボタン表示に
        });

      });
    });
  }



})


/* 
    デフォルト 
    js-autoHeightItem-parents   高さを揃えたい要素が全て内包されている親につける
    js-autoHeightItem   揃えたい要素につける
    768px以上はカラム数2、以下（スマホ時）は高さ揃えなし
*/
// window.addEventListener('load', function() {
//   autoHeightItem.init({});
// })
