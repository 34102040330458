

//---------------------------
//ヘッダーのディレクトリ判定
//---------------------------
let loc = window.location.pathname;
//let dir = loc.substring(2, loc.lastIndexOf('/'));
//console.log(dir);

if (loc.match(/property/)) {
  $("#property").toggleClass("js-on-directory");
} else if(loc.match(/concept/)){
  $("#concept").toggleClass("js-on-directory");
} else if(loc.match(/faq/)){
  $("#faq").toggleClass("js-on-directory");
} else if(loc.match(/news/)){
  $("#news").toggleClass("js-on-directory");
}