import Accordion from '../../plugins/accordion.js';


    // let accordion = new Accordion(".js-accordion2", {
    //   defaultOpenPanels: [0,1,2,3,4,5]
    // });

    // let accordion3 = new Accordion(".js-accordion3", {
    //   defaultOpenPanels: [0,1,2,3]
    // });

    // let accordion4 = new Accordion(".js-accordion4", {
    //   defaultOpenPanels: [0,1,2,3,4]
    // });

    const elements = document.querySelectorAll(".js-accordion");
    for (const element of elements) {
      let accordion = new Accordion(element, {
        multipleOpen: true
      });
    }


    