//pagetop
$(function() {
  var topBtn = $('#page-top');
  //var sideBtn = $('#side-menu');
  topBtn.hide();
  //sideBtn.hide();
  //スクロールが100に達したらボタン表示
  $(window).scroll(function () {
      if ($(this).scrollTop() > 600) {
          topBtn.fadeIn();
      } else {
          topBtn.fadeOut();
      }
    //   if ($(this).scrollTop() > 0) {
    //     sideBtn.fadeIn();
    //   } else {
    //     sideBtn.fadeOut();
    //   }
  });
  //スクロールしてトップ
  topBtn.click(function () {
      $('body,html').animate({
          scrollTop: 0
      }, 500);
      return false;
  });
});


jQuery(function($){
    $('.tab').click(function(){
        $('.is-active').removeClass('is-active');
        $(this).addClass('is-active');
        $('.is-show').removeClass('is-show');
        // クリックしたタブからインデックス番号を取得
        const index = $(this).index();
        // クリックしたタブと同じインデックス番号をもつコンテンツを表
        $('.panel').eq(index).addClass('is-show');
    });
});



var baseW = 1440;	//基準となるブレークポイント
var iOSviewportW = 0;
var ua = navigator.userAgent.toLowerCase();
var isiOS = (ua.indexOf("iphone") > -1) || (ua.indexOf("ipod") > -1) || (ua.indexOf("ipad") > -1);
if(isiOS){
	iOSviewportW = document.documentElement.clientWidth;
}
function updateMetaViewport(){
	var viewportContent;
	var w = window.outerWidth;
	if(isiOS){
		w = iOSviewportW;
	}
	if(w < baseW && w > 768){
		viewportContent = "width="+baseW+"px,user-scalable=no,shrink-to-fit=yes";
	}else{
		viewportContent = "width=device-width,user-scalable=no,shrink-to-fit=yes";
	}
	document.querySelector("meta[name='viewport']").setAttribute("content", viewportContent);
}
//イベントハンドラ登録
window.addEventListener("resize", updateMetaViewport, false);
window.addEventListener("orientationchange", updateMetaViewport, false);
//初回イベント強制発動
var ev = document.createEvent("UIEvent");
ev.initEvent("resize", true, true)
window.dispatchEvent(ev);


// window.onresize = function(){

//     var baseW = 1440;	//基準となるブレークポイント
//     var iOSviewportW = 0;
//     var ua = navigator.userAgent.toLowerCase();
//     var isiOS = (ua.indexOf("iphone") > -1) || (ua.indexOf("ipod") > -1) || (ua.indexOf("ipad") > -1);
//     if(isiOS){
//         iOSviewportW = document.documentElement.clientWidth;
//     }
//     function updateMetaViewport(){
//         var viewportContent;
//         var w = window.outerWidth;
//         if(isiOS){
//             w = iOSviewportW;
//         }
//         if(w < baseW && w > 768){
//             viewportContent = "width="+baseW+"px,user-scalable=no,shrink-to-fit=yes";
//         }else{
//             viewportContent = "width=device-width,user-scalable=no,shrink-to-fit=yes";
//         }
//         document.querySelector("meta[name='viewport']").setAttribute("content", viewportContent);
//     }
//     //イベントハンドラ登録
//     window.addEventListener("resize", updateMetaViewport, false);
//     window.addEventListener("orientationchange", updateMetaViewport, false);
//     //初回イベント強制発動
//     var ev = document.createEvent("UIEvent");
//     ev.initEvent("resize", true, true)
//     window.dispatchEvent(ev);

// }